import * as React from 'react';

import { dalGetAuthAccount } from '@hub-client-api';

import Admin from '@hub-fe/admin/Admin';
import IsBrowser from '@hub-fe/common/IsBrowser';
import apiTokens from '@hub-fe/common/apiTokens';
import RoutingProvider from '@hub-fe/common/routing/RoutingContext';
import { login } from '@hub-fe/common/urls';
import { DamlHubHelmet } from '@hub-fe/landing/nav';
import { MessageProvider } from '@hub-fe/messages/MessageContext';

import { isLoggedIn } from '../service/auth';

const AdminApp: React.FC = () => {
    const [loading, setLoading] = React.useState(true);

    const doEnsureAdminCredentials = async () => {
        try {
            const { isAdmin } = await dalGetAuthAccount();
            if (!isLoggedIn() || !isAdmin) {
                throw new Error('Not Admin User');
            }
            setLoading(false);
        } catch (ex) {
            window.location.assign(`https://${login}`);
        }
    };

    React.useEffect(() => {
        doEnsureAdminCredentials();
        return () => apiTokens.clear();
    }, []);

    if (loading) {
        return <></>;
    }

    return (
        <IsBrowser>
            <DamlHubHelmet />
            <RoutingProvider>
                <MessageProvider>
                    <Admin />
                </MessageProvider>
            </RoutingProvider>
        </IsBrowser>
    );
};

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default AdminApp;
